var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-img',_vm._b({ref:"imageElement",on:{"load":_vm.loaded},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_vm._t("placeholder")]},proxy:true}],null,true)},'v-img',_vm.computedProps,false),[_c('div',{style:({
      height: _vm.image.height + 'px',
      width: _vm.image.width + 'px',
      position: 'relative',
      margin: 'auto',
      zIndex: 100,
    })},[_c('canvas',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],ref:"canvas",staticClass:"canvas",on:{"mousedown":_vm.onMouseDown,"mouseup":_vm.onMouseUp,"mousemove":_vm.onMouseMove,"mouseleave":_vm.onMouseUp,"click":_vm.onMouseClick}}),_c('v-menu',{attrs:{"value":_vm.menu.show,"position-x":_vm.menu.x,"position-y":_vm.menu.y,"close-on-click":false,"transition":"fade-transition","absolute":"","offset-y":""}},[_vm._t("context")],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }